// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Q02VPj41I", "i_8rSopxW"];

const variantClassNames = {i_8rSopxW: "framer-v-537x78", Q02VPj41I: "framer-v-1j7z2vn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Button Primary": "Q02VPj41I", "Grey Hover": "i_8rSopxW"};

const transitions = {default: {damping: 2, delay: 0, duration: 0, ease: [0, 0, 1, 1], mass: 10, stiffness: 13, type: "tween"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; contactButton?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Q02VPj41I", contactButton: Sneg04CwL = "Lets Talk", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Q02VPj41I", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnternfc8y3 = activeVariantCallback(async (...args) => {
setVariant("i_8rSopxW")
})

const onMouseLeave1birom4 = activeVariantCallback(async (...args) => {
setVariant("Q02VPj41I")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ESo9w", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1j7z2vn", className)} data-border data-framer-name={"Button Primary"} data-highlight layoutDependency={layoutDependency} layoutId={"Q02VPj41I"} onMouseEnter={onMouseEnternfc8y3} onMouseLeave={onMouseLeave1birom4} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(221, 221, 221)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(255, 255, 255, 0)", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}} transformTemplate={transformTemplate} transition={transition} {...addPropertyOverrides({i_8rSopxW: {"data-framer-name": "Grey Hover"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Get Started</motion.p></React.Fragment>} className={"framer-1map4j3"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"SF3kN8dWp"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px", opacity: 1}} text={Sneg04CwL} transition={transition} variants={{i_8rSopxW: {opacity: 0.51}}} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ESo9w [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ESo9w * { box-sizing: border-box; }", ".framer-ESo9w .framer-1ni9099 { display: block; }", ".framer-ESo9w .framer-1j7z2vn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: hidden; padding: 15px 15px 15px 15px; position: relative; width: min-content; will-change: transform; }", ".framer-ESo9w .framer-1map4j3 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ESo9w .framer-1j7z2vn { gap: 0px; } .framer-ESo9w .framer-1j7z2vn > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ESo9w .framer-1j7z2vn > :first-child { margin-left: 0px; } .framer-ESo9w .framer-1j7z2vn > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 91
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"i_8rSopxW":{"layout":["auto","fixed"]}}}
 * @framerVariables {"Sneg04CwL":"contactButton"}
 */
const Framervhrw2I333: React.ComponentType<Props> = withCSS(Component, css, "framer-ESo9w") as typeof Component;
export default Framervhrw2I333;

Framervhrw2I333.displayName = "Button Secondary";

Framervhrw2I333.defaultProps = {height: 40, width: 91};

addPropertyControls(Framervhrw2I333, {variant: {options: ["Q02VPj41I", "i_8rSopxW"], optionTitles: ["Button Primary", "Grey Hover"], title: "Variant", type: ControlType.Enum}, Sneg04CwL: {defaultValue: "Lets Talk", displayTextArea: false, placeholder: "", title: "Contact Button", type: ControlType.String}} as any)

addFonts(Framervhrw2I333, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/vhrw2I333:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf", weight: "700"}])